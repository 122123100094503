import Vue from 'vue';
import axios from "axios";
import onPic from '../assets/no-picture.png'
import { Notification } from 'element-ui';
var vue = new Vue();
// var pre = process.env.NODE_ENV == 'production' ? '' : '/piaodu';
var pre = '/piaodu';
// console.log(JSON.stringify([1,2]))
Date.prototype.format = function(format) {
	var date = {
		"M+": this.getMonth() + 1,
		"d+": this.getDate(),
		"h+": this.getHours(),
		"m+": this.getMinutes(),
		"s+": this.getSeconds(),
		"q+": Math.floor((this.getMonth() + 3) / 3),
		"S+": this.getMilliseconds()
	};
	if (/(y+)/i.test(format)) {
		format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
	}
	for (var k in date) {
		if (new RegExp("(" + k + ")").test(format)) {
			format = format.replace(RegExp.$1, RegExp.$1.length == 1 ?
				date[k] : ("00" + date[k]).substr(("" + date[k]).length));
		}
	}
	return format;
}

function getTree(data) {
	let map = {};
	data.forEach(item => {
		map[item.id] = item
	})
	let tree = [];
	data.forEach(item => {
		let parent = map[item.pid];
		console.log(parent,map)
		if (parent) {
			(parent.childrens||(parent.childrens=[])).push(item)
		} else {
			tree.push(item);
		}
	})
	return tree;
}

function getWeekDayTime() {
	let date = new Date();
	let times = date.getTime();
	let day = date.getDay()
	if (day == 0) {
		day = 7;
	}
	day = day - 1;
	let hour = date.getHours()
	let minute = date.getMinutes()
	let second = date.getSeconds()
	let dayTime = times - day * 3600 * 24000 - hour * 3600 * 1000 - minute * 60 * 1000 - second * 1000
	return dayTime
}

axios.interceptors.request.use(function(config) {
	config.withCredentials=true;
	config.timeout=100000;
	 var PIAODU_JSESSIONID = localStorage.getItem('PIAODU_XF')
	if (!PIAODU_JSESSIONID) {
		// PIAODU_JSESSIONID = '178b0bae-7bbb-4212-b153-e4ec677702ac';
		// PIAODU_JSESSIONID = '185a22d4-05b1-493d-a37d-469980faabc0'
		// PIAODU_JSESSIONID = 'd53fc4fb-4a8b-4051-9356-b7dd5dc17b3f'
		PIAODU_JSESSIONID = ''
	}
	config.headers = {
		'PIAODU_JSESSIONID': PIAODU_JSESSIONID,
	}
	return config
}, function(error) {
	return Promise.reject(error);
})
//	var localUrl = 'http://192.168.0.5:9090/'+url


async function getAjax(url, data,_this,sucFun, errFun,commonFun, type) {
	var localUrl = pre + url;
	data.vtime = new Date().getTime();
	await axios.get(localUrl, {
			params: data,
			timeout: 10000, //超时时间设置为10秒；
			headers: {
				'Content-Type': 'application/json'
			},
		})
		.then(function(res) {
			// debugger
			if(comjs.loading){
				comjs.loading = false
			}
			if(commonFun){
				commonFun(res.data);
				if(type) {
					return;
				}
			}
			if (res.data.status == 999 || res.data.status == 997 || res.data.status == 403) {
				// _this.isConti = false;
				// _this.$emit('clearInterval')
				vue.$message({
					showClose: true,
					message: res.data.message,
					type: 'warning'
				})
				// setTimeout(()=>{
					var url = _this.$route.path
					_this.$router.replace({name:'login',params:{url:url}})
				// },200)
				
			} else if (res.data.status == 200) {
				if (sucFun) {
					sucFun(res.data)
				}
			}else{
				vue.$message({
					showClose: true,
					message: res.data.message,
					type: 'warning'
				})
				// if(_this.progressDialogVisible){
				// 	_this.progressDialogVisible = false;
				// }
				if(errFun){
					errFun(res.data)
				}
				// return false;
			}
			
			// console.log(res)

		})
		.catch(function(error) {
			vue.$message({
				showClose: true,
				message: error.message,
				type: 'warning'
			})
			if(comjs.loading){
				comjs.loading = false
			}
			if(errFun){
				errFun()
			}
			// Vue.$me
			console.log(error);
		});
}

async function postAjax(url, data,_this,sucFun, errFun,test) {
	if(test){
		var localUrl = comjs.piaoDuHost + url
	}else{
		var localUrl = pre + url
	}
	var localData = new FormData();
	for (var i in data) {
		localData.append(i, data[i])
	}
	await axios.post(localUrl, localData)
		.then(function(res) {
			if (res.data.status == 999 || res.data.status == 997 || res.data.status == 403) {
				vue.$message({
					showClose: true,
					message: res.data.message,
					type: 'warning'
				})
				var url = _this.$route.path
				_this.$router.replace({name:'login',params:{url:url}})
			} else if (res.data.status == 200) {
				if (sucFun) {
					sucFun(res.data)
				}
			} else {
				if(errFun){
					errFun(res.data)
				}else{
					vue.$message({
						showClose: true,
						message: res.data.message,
						type: 'warning'
					})
				}
			}
		})
		.catch(function(error) {

			vue.$message({
				showClose: true,
				message: error.message,
				type: 'warning'
			})
			// Vue.$me
			console.log(error);
		});
}

function postJsonAjax(url, data,_this,sucFun, errFun) {
	var localUrl = pre + url
	axios({
			url: localUrl,
			method: 'post',
			//发送格式为json
			data,
			headers: {
				'Content-Type': 'application/json'
			}
		})
		.then(function(res) {
			if (res.data.status == 999 || res.data.status == 997 || res.data.status == 403) {
				vue.$message({
					showClose: true,
					message: res.data.message,
					type: 'warning'
				})
				var url = _this.$route.path
				_this.$router.replace({name:'login',params:{url:url}})
			} else if (res.data.status == 200) {
				if (sucFun) {
					sucFun(res.data)
				}
			} else {
				if(errFun){
					errFun(res.data)
				}else{
					vue.$message({
						showClose: true,
						message: res.data.message,
						type: 'warning'
					})
				}
			}
		})
		.catch(function(error) {
			vue.$message({
				showClose: true,
				message: error.message,
				type: 'warning'
			})
			// Vue.$me
			console.log(error);
		});
}

function timeChange(value, lastTimer) {
	// console.log(value)
	if(!value){
		return '';
	}
	if (value.indexOf('-') >= 0) {
		value = value.replace(/-/g, '/')
	}
	var createValue = new Date(value);
	var createTime = createValue.getTime()
	var nowTime = new Date().getTime();
	console.log()
	if (lastTimer) {
		if (lastTimer.indexOf('-') >= 0) {
			lastTimer = lastTimer.replace(/-/g, '/')
		}
		var lastTime = new Date(lastTimer);
		if (createValue.getFullYear() == lastTime.getFullYear() && createValue.getMonth() == lastTime.getMonth() &&
			createValue.getDate() == lastTime.getDate() && createValue.getHours() == lastTime.getHours() && createValue.getMinutes() ==
			lastTime.getMinutes()) {
			return false;
		}
		// if (createTime < lastTime * 1 + 1000 * 60) {
		// 	console.log(createTime,lastTime)
		// 	return false;
		// }
	}
	value = value.substring(0, value.length - 3)
	var zeroTimer = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
	// if(nowTime > createTime * 1 + 1000*60){
	if (createTime < zeroTimer) {
		var yesterdayTimer = zeroTimer - 1000 * 60 * 60 * 24;
		if (createTime < yesterdayTimer) {
			var weekDayTime = getWeekDayTime();
			if (createTime < weekDayTime) {
				return value.replace('/', '年').replace('/', '月').replace(' ', '日 ')
			} else {
				value = value.substring(11);
				if (createValue.getDay() == 1) {
					return '星期一 ' + value;
				} else if (createValue.getDay() == 2) {
					return '星期二 ' + value;
				} else if (createValue.getDay() == 3) {
					return '星期三 ' + value;
				} else if (createValue.getDay() == 4) {
					return '星期四 ' + value;
				} else if (createValue.getDay() == 5) {
					return '星期五 ' + value;
				} else if (createValue.getDay() == 6) {
					return '星期六 ' + value;
				} else {
					return '星期日 ' + value;
				}
			}

		} else {
			value = value.substring(11);
			return '昨天 ' + value;
		}

	} else {
		value = value.substring(11);
		return '今天 ' + value;
	}

}

var comjs = {
	getTree,
	loginType:'',
	timeChange,
	initPage:{
		page:1,
		pageSize:5,
		sidx: 'createdTxStamp',
		sord: 'desc',
	},
	axios,
	Notification,
	handInitPage(pageSize){
		this.initPage.sidx = 'createdTxStamp';
		this.initPage.sord = 'desc';
		this.initPage.pageSize = pageSize
	},
	sortChange(obj,obj2,fn) {
		obj2.sidx = obj.prop;
		if (obj.order) {
			if (obj.order == 'ascending') {
				obj2.sord = 'asc'
			} else {
				obj2.sord = 'desc'
			}
		} else {
			obj2.sord = ''
		}
		fn();
	},
	loading:false,
	total:0,
	host: '',
	piaoDuHost:'https://mobile.piaoduwang.cn/',
	currentHost:'',
	pre,
	ajax: {
		getAjax,
		postAjax,
		postJsonAjax
	},
	handleSizeChange(val,fn) {
		this.initPage.pageSize = val;
		fn();
	},
	// pid = 'pId'
	// id = 'id', pId = 'pid'
	treeDataTranslate(data,id = 'id', pid = 'pId') {
	  var res = []
	  var temp = {}
	  for (var i = 0; i < data.length; i++) {
	    temp[data[i][id]] = data[i]
	  }
	  for (var k = 0; k < data.length; k++) {
	     if (temp[data[k].pId] && data[k][id] !== data[k].pId) {
	      if (!temp[data[k].pId]['childrens']) {
	        temp[data[k].pId]['childrens'] = []
	      }
	      if (!temp[data[k].pId]['_level']) {
	        temp[data[k].pId]['_level'] = 1
	      }
	      data[k]['_level'] = temp[data[k].pId]._level + 1
	      temp[data[k].pId]['childrens'].push(data[k])
	    } else {
	      res.push(data[k])
	    }
	   
	  }
	  return res
	},
	handleCurrentChange(val,fn) {
		this.initPage.page = val
		fn();
	},
	inputNum: (e, prop) => {
		e.target.value = e.target.value.replace(/[^\d]/g, '');
		prop = e.target.value;
		// console.log(prop)
	},
	inputPrice:(e, prop) => {
		e.target.value = e.target.value.replace(/[^\d.]/g, '');
		prop = e.target.value;
		console.log(prop,e.target.value)
	},
	imgPd(value) {
		return value ? value.split(',')[0].indexOf('mobile.piaoduwang.cn') >= 0 ? value.split(',')[0].replace('https://mobile.piaoduwang.cn','') : value.split(',')[0] : onPic
	},
	imgPd2(value) {
		return value ? value.indexOf("mobile.piaoduwang.cn") >= 0 ? value.replace('https://mobile.piaoduwang.cn','') :value : ''
	},
	reload(e) {
		// e.target.src = onPic
		console.log(e.target)
	},
	vue,
	// 用于预览图片重新排序
	resetArr(value,arr){
		var leftArr = [];
		var index = arr.indexOf(value);
		console.log(value,index)
		if(index > 0){
			for(var i=0;i<index;i++){
				leftArr.push(arr[i])
			}
			var rightArr = [];
			for (var i=index;i<arr.length;i++) {
				rightArr.push(arr[i])
			}
			arr = [...rightArr,...leftArr];
		}
		console.log(arr)
		return arr
	},
	// 表格label尺寸
	formLabelWidth:'120px',
	// 接口返回图片转化成数组
	imgArrOpe(imgArr, localImgArr) {
		if (imgArr) {
			var imgUrls = imgArr.split(',');
			for (var i in imgUrls) {
				var imgUrl = imgUrls[i].indexOf('http') >= 0 ? imgUrls[i] : this.host + imgUrls[i];
				localImgArr.push({
					url: imgUrl
				})
			}
		}
		return localImgArr;
	},
	//上传到服务器的图片转换成json格式
	integrationImg(data){
		var retData = [];
		console.log(data)
		for(var i in data){
			if(data[i].response){
				var url =this.host+data[i].response.data.url;
				retData.push(url)
			}else{
				retData.push(data[i].url)
			}
		}
		return retData.join(',')
	},
	formSure(formName,_this,fun,errFun){
		_this.$refs[formName].validate((valid) => {
			if (valid) {
				if(fun){
					fun();
				}
			}else{
				_this.$message({
					showClose: true,
					message: '请把必填项填写完整',
					type: 'warning'
				})
				if(errFun){
					errFun()
				}
				return false;
			}
		})
	}
}
Vue.prototype.$comjs = comjs;
