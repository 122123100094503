import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  tabArr:[{
		  name:'首页',
		  url:''
	  }],
	  stageArr:[],
	  gradeArr:[],
	  vertionArr:[],
	  subjectArr:[],
	  tixingArr:[],
	  isLoadSort:false,
	  filter: [{
	  	"col": "status",
	  	"type": "=",
	  	"val": "1"
	  }, {
	  	"col": "periodName",
	  	"type": "like",
	  	"val": ""
	  }, {
	  	"col": "phaseId",
	  	"type": "like",
	  	"val": ""
	  },{
	  	"col": "gradeId",
	  	"type": "like",
	  	"val": ""
	  },{
	  	"col": "subjectId",
	  	"type": "like",
	  	"val": ""
	  },{
	  	"col": "versionId",
	  	"type": "like",
	  	"val": ""
	  },{
	  	"col": "unitName",
	  	"type": "like",
	  	"val": ""
	  }]
  },
  mutations: {
	  addTabArr(state,payload){
		  if(state.tabArr.length > 0){
			  for(var i in state.tabArr){
				  if(state.tabArr[i].name == payload.name){
					  break;
				  }
			  }
			  if(i < state.tabArr.length){
				  return ;
			  }else{
				  state.tabArr.push(payload)
			  }
		  }else{
			  state.tabArr.push(payload)
		  }
	  },
	  changeSingleSort(state,payload){
		  state[payload.name] = payload.arr
	  },
	  changesortData(state,flag){
		  state.isLoadSort = flag;
	  }
  },
  actions: {
	  
  },
  modules: {
  }
})
