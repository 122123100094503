import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import router from './router'
//引入nprogress
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' //这个样式必须引入

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			name:'首页'
		},
		children: [{
			path: 'system',
			name: 'system',
			component: () => import('../views/system/index.vue'),
			meta: {
				keepAlive: false,
				name:'系统管理'
			},
			children: [{
				path: 'menu',
				name: 'menu',
				component: () => import('../views/system/menu/index.vue'),
				meta: {
					keepAlive: false,
					name:'菜单管理'
				}
			},
			{
				path: 'organization',
				name: 'organization',
				component: () => import('../views/system/organization/index.vue'),
				meta: { 
					keepAlive: false,
					name:'账号管理'
				}
			},
			{
				path: 'rolemanagement',
				name: 'rolemanagement',
				component: () => import('../views/system/rolemanagement/index.vue'),
				meta: { 
					keepAlive: false,
					name:'角色管理'
				}
			},
			{
				path: 'password',
				name: 'password',
				component: () => import('../views/system/password/index.vue'),
				meta: { 
					keepAlive: false,
					name:'修改密码'
				}
			},
			{
				path: 'message',
				name: 'message',
				component: () => import('../views/system/message/index.vue'),
				meta: { 
					keepAlive: false,
					name:'消息管理'
				}
			}
			]
		},{
			path: 'subject',
			name: 'subject',
			component: () => import('../views/subject/index.vue'),
			meta: {
				keepAlive: false,
				name:'题库管理'
			},
			children: [{
				path: 'classify',
				name: 'classify',
				component: () => import('../views/subject/classify/index.vue'),
				meta: {
					keepAlive: false,
					name:'题目分类管理'
				}
			}, {
				path: 'genre',
				name: 'genre',
				component: () => import('../views/subject/genre/index.vue'),
				meta: {
					keepAlive: false,
					name:'题目类型管理'
				}
			}, {
				path: 'topic',
				name: 'topic',
				component: () => import('../views/subject/topic/index.vue'),
				meta: {
					keepAlive: false,
					name:'题目管理'
				}
			}, {
				path: 'paper',
				name: 'paper',
				component: () => import('../views/subject/paper/index.vue'),
				meta: {
					keepAlive: false,
					name:'套题管理'
				}
			}]
		}, {
			path: 'game',
			name: 'game',
			component: () => import('../views/game/index.vue'),
			meta: {
				keepAlive: false,
				name:'赛事管理'
			},
			children: [{
				path: 'area',
				name: 'area',
				component: () => import('../views/game/area/index.vue'),
				meta: {
					keepAlive: false,
					name:'区域管理'
				}
			},{
				path: 'advertising',
				name: 'advertising',
				component: () => import('../views/game/advertising/index.vue'),
				meta: {
					keepAlive: false,
					name:'广告管理'
				}
			},{
				path: 'type',
				name: 'type',
				component: () => import('../views/game/type/index.vue'),
				meta: {
					keepAlive: false,
					name:'赛制管理'
				}
			},{
				path: 'phase',
				name: 'phase',
				component: () => import('../views/game/phase/index.vue'),
				meta: {
					keepAlive: false,
					name:'赛事阶段管理'
				}
			}, {
				path: 'process',
				name: 'process',
				component: () => import('../views/game/process/index.vue'),
				meta: {
					keepAlive: true,
					name:'流程模板'
				}
			}, {
				path: 'competition',
				name: 'competition',
				component: () => import('../views/game/competition/index.vue'),
				meta: {
					keepAlive: false,
					name:'赛事管理'
				}
			}, {
				path: 'room',
				name: 'room',
				component: () => import('../views/game/room/index.vue'),
				meta: {
					keepAlive: false,
					name:'房间管理'
				}
			}, {
				path: 'ceiling',
				name: 'ceiling',
				component: () => import('../views/game/ceiling/index.vue'),
				meta: {
					keepAlive: false,
					name:'系统配置'
				}
			}]
		}, {
			path: 'playerMent',
			name: 'playerMent',
			component: () => import('../views/playerMent/index.vue'),
			meta: {
				keepAlive: false,
				name:'玩家管理'
			},
			children: [{
				path: 'player',
				name: 'player',
				component: () => import('../views/playerMent/player/index.vue'),
				meta: {
					keepAlive: false,
					name:'玩家管理'
				}
			},
			{
				path: 'userHistory',
				name: 'userHistory',
				component: () => import('../views/playerMent/history/index.vue'),
				meta: {
					keepAlive: false,
					name:'用户参赛历史'
				}
			}]
		}, {
			path: 'mall',
			name: 'mall',
			component: () => import('../views/mall/index.vue'),
			meta: {
				keepAlive: false,
				name:'积分商城管理'
			},
			children: [{
				path: 'goodsList',
				name: 'goodsList',
				component: () => import('../views/mall/goodsList/index.vue'),
				meta: {
					keepAlive: false,
					name:'积分商品管理'
				}
			}, {
				path: 'classify',
				name: 'classify',
				component: () => import('../views/mall/classify/index.vue'),
				meta: {
					keepAlive: false,
					name:'商品分类管理'
				}
			}, {
				path: 'grouping',
				name: 'grouping',
				component: () => import('../views/mall/grouping/index.vue'),
				meta: {
					keepAlive: false,
					name:'商品分组管理'
				}
			}, {
				path: 'orderMent',
				name: 'orderMent',
				component: () => import('../views/mall/orderMent/index.vue'),
				meta: {
					keepAlive: false,
					name:'订单管理'
				}
			}, {
				path: 'integral',
				name: 'integral',
				component: () => import('../views/mall/integral/index.vue'),
				meta: {
					keepAlive: false,
					name:'积分分布管理'
				}
			}, {
				path: 'logistics',
				name: 'logistics',
				component: () => import('../views/mall/logistics/index.vue'),
				meta: {
					keepAlive: false,
					name:'各省运费管理'
				}
			}],
		}]
	},
	// {
	// 	path: '/about',
	// 	name: 'About',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
	// }
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/index.vue'),
	},
	{
		path: '/test',
		name: 'test',
		component: () => import('../views/system/menu/index.vue'),
	},
]

const router = new VueRouter({
	routes
})

NProgress.inc(0.2)
NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })


router.beforeEach((to,from,next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})
export default router
