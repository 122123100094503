<template>
  <div id="app">
		<router-view v-if="isloading"></router-view>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl">
		</el-dialog>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  },
  data() {
  	return {
  		// entityArr:['PdYsfExamPhase','PdYsfExamGrade','PdYsfExamVersion','PdYsfExamSubject','PdYsfExamQuestionType'],
		// storenameArr:['stageArr','gradeArr','vertionArr','subjectArr','tixingArr'],
		isloading:false,
		filter: {
			"col": "status",
			"type": "=",
			"val": "1",
		},
		dialogVisible: false,
		dialogImageUrl: '',
  	}
  },
  methods:{
  	 //  async loadbaseData(){
		  // for(var i in this.entityArr){
			 //  var data = {
			 //  	entity: this.entityArr[i],
			 //  	filter:JSON.stringify(this.filter),
			 //  	"sidx":"pdSort",
			 //  	"sord":"ASC"
			 //  }
			 //  await this.$comjs.ajax.getAjax('/jqGrid/findList', data, this, (res) => {
				// 	this.$store.commit('changeSingleSort',{name:this.storenameArr[i],arr:res.list});
			 //  })
		  // }
  		//   this.isloading = true;
  	 //  }
  },
  created() {
  	  // this.loadbaseData();
	    this.isloading = true;
		this.$comjs.vue.$on('bigImg', (url) => {
			if (url.indexOf('http') < 0) {
				url = this.$comjs.host + url
			}
			this.dialogImageUrl = url;
			this.dialogVisible = true;
		})
  }
}
</script>

<style lang="scss" >
// @import './assets/common.scss';
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
body,html{
	margin: 0;
	padding: 0;
	overflow: hidden;
}
.must {
    color: #f56c6c;
    padding-left: 5px;
}



</style>
