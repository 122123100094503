<template>
	<el-container class='appAll' v-loading='loading'>
		<el-menu :default-active="$route.path" class="el-menu-vertical-demo" :collapse="isCollapse" :router='true'>
			<div :class="['logoPart',isCollapse?'':'margin-right']">
				<i class="piaoduLogo"></i>
				<span slot="title" v-if="!isCollapse">漂读网络科技</span>
			</div>
			<el-scrollbar style='height:calc(100% - 45px);overflow:hidden;'>
				<el-menu-item index="/">
					<i class="el-icon-menu"></i>
					<span slot="title">首页</span>
				</el-menu-item>
				<el-submenu :index="item.data.url" v-for="item in listArr">
					<template slot="title">
						<i :class="item.icon"></i>
						<span>{{item.name}}</span>
					</template>
					<el-menu-item :index="single.data.url" v-for="single in item.childrens"><i :class="single.icon"></i>{{single.name}}</el-menu-item>
				</el-submenu>
			</el-scrollbar>
		</el-menu>
		<div class="content_center">
			<div class="content_header">
				<div class="foldPart" @click="isCollapse = !isCollapse">
					<i :class="[isCollapse?'el-icon-s-unfold':'el-icon-s-fold']"></i>
				</div>
				<div class="menuPart">
					<el-breadcrumb separator="/">
						<template v-for="(item,index) in $route.matched">
							<el-breadcrumb-item :to="{ path: '/' }" v-if="index == 0 && $route.matched.length > 0">{{item.meta.name}}</el-breadcrumb-item>
							<el-breadcrumb-item v-else-if='isBianji(index)'>占位符</el-breadcrumb-item>
							<el-breadcrumb-item v-else >{{item.meta.name}}</el-breadcrumb-item>
						</template>
					</el-breadcrumb>
					<el-popover placement="bottom" trigger="click" width="80" popper-class='selfPopover'>
						<div class="loginOut" @click="loginout">退出登录</div>
						<div class="headPortrait" slot="reference">
							<span class="userName">{{userLoginId}}</span>
							<el-avatar shape="square" size="large" :src="squareUrl" class='headImg'></el-avatar>
							<i class="el-icon-caret-bottom"></i>
						</div>
					</el-popover>
				</div>
			</div>
			<div class="content_tab">
				<el-scrollbar>
					<el-tabs v-model="currentTab" type="card" @tab-remove='tabRemove' @tab-click="changeRoute">
						<el-tab-pane v-for="(item, index) in tabsArr()" :key="index * 1 + 1" :label="item.name" :closable='index > 0' :name='""+(index * 1 + 1)'>
						</el-tab-pane>
					</el-tabs>
				</el-scrollbar>
			</div>
			<keep-alive>
				<router-view v-if="isCanShow" @add="loadData"></router-view>
			</keep-alive>
			<div :style="{width: 'calc(100vw - '+this.leftWidth+'px)',height:'calc(100vh - 110px)'}" class="homeBg" v-if="$route.fullPath == '/'">
				<img :src="logo" style="width:45%;" >
			</div>
		</div>
	</el-container>
</template>
<script>
	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				isCollapse: false,
				squareUrl: require('../assets/profile.jpg'),
				tabslocalArr: [{
					name: '首页',
					path: '/'
				}],
				currentTab: '1',
				listArr: [],
				loading: false,
				isCanShow: false,
				leftWidth:200,
				logo: require('../assets/homeBg.jpg'),
				userLoginId: ''
			}
		},
		created() {
			window.addEventListener('resize', () => {
				var obj = this.getViewportOffset();
				if (obj.w < 1050) {
					this.isCollapse = true;
					this.leftWidth = 74;
				}else{
					this.isCollapse = false;
					this.leftWidth = 230;
				}
			})
			var obj = this.getViewportOffset();
			if (obj.w < 1050) {
				this.isCollapse = true;
				this.leftWidth = 74;
			} else {
				this.isCollapse = false;
				this.leftWidth = 230;
			}
			this.loadData();
			// console.log(this.$route)
		},
		computed:{
			
		},
		methods: {
			isBianji(index){
				if(index == 2){
					if(this.$route.params.id){
						if(this.$route.params.id.length > 10){
							return true;
						}
						return false;
					}
					return false;
				}
				return false;
			},
			tabsArr() {
				let path = this.$route.fullPath;
				var flag = false;
				for (var i in this.tabslocalArr) {
					if (this.tabslocalArr[i].path == path) {
						flag = true;
						this.currentTab = String(i * 1 + 1);
						break;
					}
				}
				return this.tabslocalArr;
			},
			itemUrl(single) {
				if (single.data.url.indexOf('addnewone') >= 0) {
					if (this.$route.params.id) {
						if (this.$route.params.id.length > 10) {
							return '/questionbankMan/addnewone/' + this.$route.params.id;
						}
						return single.data.url;
					}
					return single.data.url;
				}
				return single.data.url
			},
			loginout() {
				this.$comjs.ajax.getAjax('/fa/system/logout', {}, this, (res) => {
					this.$message({
						message: '退出成功',
						type: 'success'
					})
					this.$router.push({path:'/login',params:this.$route.path})
				})
			},
			async loadData() {console.log('data')
				// console.log(this.$route.fullPath)
				this.loading = true;
				var data = {

				}
				await this.$comjs.ajax.getAjax('/fa/system/getUserLoginInfo', data, this, (res) => {
					this.$comjs.loginType = res.data.id;
					this.userLoginId = res.data.userLoginId;
				})
			
				if (this.$comjs.loginType) {
					await this.$comjs.ajax.getAjax('/fa/admin/system/menu/getUserMenuList', {}, this, (res) => {
						this.listArr = res.list;
						this.isCanShow = true;
						this.loading = false;
					})
				}

			},
			changeRoute(obj) {
				if (this.$route.fullPath == this.tabslocalArr[obj.name - 1].path) {
					return false;
				}
				this.$router.push({
					path: this.tabslocalArr[obj.name - 1].path
				})
			},
			tabRemove() {
				this.tabslocalArr.splice(-1, 1);
				var index = this.tabslocalArr.length;
				this.currentTab = index;
				this.$router.push({
					path: this.tabslocalArr[index - 1].path
				})
			},
			getViewportOffset() {
				if (window.innnerWidth) {
					return {
						w: window.innerWidth,
						h: window.innerHeight
					}
				} else {
					if (document.compatMode === "BackCompat") {
						return {
							w: document.body.clientWidth,
							h: document.body.clientHeight
						}
					} else {
						return {
							w: document.documentElement.clientWidth,
							h: document.documentElement.clientHeight
						}
					}
				}
			},

		}
	}
</script>
<style lang="scss" scoped="scoped">
	.appAll {
		height: 100vh;
		width: 100vw;
		display: flex;

		.el-menu-vertical-demo:not(.el-menu--collapse) {
			max-width: 200px;
			min-width: 150px;
			flex-grow: 1;
		}

		.el-menu--collapse .el-submenu__title span {
			height: 0;
			width: 0;
			overflow: hidden;
			visibility: hidden;
			display: inline-block;
		}

		// .el-menu--collapse{width: 64px;}
		.logoPart {
			height: 60px;
			min-width: 70px;
			@extend %flex-center;
			cursor: pointer;

			.piaoduLogo {
				display: inline-block;
				background: url(../assets/logo.png);
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-size: 100% 100%;
				// margin-right: 10px;
			}

			.margin-right {
				margin-right: 10px;
			}
		}

		.content_center {
			// width:-webkit-calc()
			flex: 1;
			overflow: auto;
			max-width: calc(100vw - 64px);

			// overflow-y:auto;
			.content_header {
				height: 50px;
				display: flex;
				box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

				.foldPart {
					// @extend %flex-center;
					height: 100%;
					line-height: 50px;
					padding: 0 15px;
					font-size: 22px;
					cursor: pointer;
					display: inline-block;

					&:hover {
						background: #F9F9F9;
					}
				}

				.menuPart {
					display: flex;
					justify-content: space-between;
					width: calc(100% - 52px);
					padding-left: 10px;
					padding-right: 20px;

					.el-breadcrumb .el-breadcrumb__item {
						height: 50px;
						line-height: 50px;
					}

					.headPortrait {
						// padding-top: 5px;
						cursor: pointer;
						position: relative;
						padding: 5px 15px 0;

						.el-icon-caret-bottom {
							position: absolute;
							bottom: 5px;
							right: 0px;
						}

						&:hover {
							background: #F9F9F9;
						}
					}

				}

			}
		}

		.content_tab {
			position: sticky;
			top: 0;
			z-index: 999;
			background: white;
		}

		.progress {
			position: fixed;
			top: 0px;
			left: 0;
			width: 100%;
		}
	}
    .homeBg{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	>>>.el-scrollbar::-webkit-scrollbar {
		// display: none;
		width: 20px;
		background: red;

	}
	.userName {
		display: inline-block;
		margin-right: 10px;
		line-height: 40px;
		vertical-align: top;
	}
</style>
<style lang="scss">
	.marginLeft0 {
		margin-left: 0 !important;
	}
	.marginTop5 {
		margin-top: 5px !important;
	}
	.marginRight10 {
		margin-right: 10px !important;
	}
</style>
